header#header {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 .5rem .5rem rgba(0, 0, 0, 0.2);
}

header#header.dark {
  background-color: rgba(12, 12, 12, 0.9);
  color: white;
}

header#header.dark svg {
  fill: currentColor;
}

header#header > div {
  padding: .5rem 1rem;
  width: 100%;
  max-width: 1440px;
  display: flex;
}

header#header > div > .title {
  display: flex;
  align-items: center;
}

header#header > div> #links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1 1;
}

header#header > div> #links > button {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  height: 1.25rem !important;
  width: 1.25rem !important;
  padding-top: .25rem;
  transition: .2s;
}

header#header > div> #links > button:active {
  transform: scale(.8);
}

header#header > div > #links > button > div {
  margin-bottom: .25rem;
  background-color: white;
  height: .125rem !important;
  width: 1.25rem !important;
  border-radius: .125rem;
  pointer-events: none;
  transition: .25s;
  will-change: auto;
}

header#header > div > #links > button:hover > div {
  box-shadow: 0 0 .5rem white;
}

header#header > div > #links > button:hover > div:nth-of-type(1) {
  width: 1rem !important;
}

header#header > div > #links > button:hover > div:nth-of-type(3) {
  width: .75rem !important;
}

header#header nav > ul {
  display: flex;
  list-style: none;
  font-size: .75rem;
  margin-right: 1rem;
}

header#header nav > ul > li {
  position: relative;
  margin-right: .5rem;
  border: 1px solid transparent;
  transition: .3s;
}

header#header nav > ul > li:hover {
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-shadow: 0 0 .5rem rgba(255, 255, 255, 0.4);
}

header#header nav > ul > li > a {
  text-decoration: none;
  display: block;
  padding: .25rem .5rem;
}