@import './fonts.css';

* {
  color: inherit;
  margin: 0;
  padding: 0;
  border: 0 none transparent;
  outline: 0 none transparent;
  box-sizing: border-box;
  background-color: transparent;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Source Code Pro', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-family: 'Bebas Neue';
  font-size: 1.5rem;
}